
export const appointmentDurations = [
  { value: 15, text: '15 Minuten' },
  { value: 30, text: '30 Minuten' },
  { value: 45, text: '45 Minuten' },
  { value: 60, text: '60 Minuten' },
  { value: 75, text: '75 Minuten' },
  { value: 90, text: '90 Minuten' },
  { value: 105, text: '105 Minuten' },
  { value: 120, text: '120 Minuten' },
  { value: 135, text: '135 Minuten' },
  { value: 150, text: '150 Minuten' },
  { value: 165, text: '165 Minuten' },
  { value: 180, text: '180 Minuten' }
];

export const days = {
  'monday': "Mo",
  'tuesday': "Di",
  'wednesday': "Mi",
  'thursday': "Do",
  'friday': "Fr",
  'saturday': "Sa",
  'sunday': "So"
};

export const pricingPlans = [
  {
    id: 'basic',
    title: 'Basis',
    description: 'Für kleine Selbständige',
    price: '19',
    features: [
      '1 Mitarbeiter inklusive',
      '1 Standort unterstützt',
      'Unbegrenzte Terminbuchungen',
      'Unbegrenzte Kundenverwaltung',
      'Grundlegende Terminplanung',
      'Verwaltung von Stammkunden',
      'Kundenbindungsprogramme',
      'Erweiterte Vorkasse-Konfiguration',
      'E-Mail-Benachrichtigungen',
      'Erfassung von Kundenfeedback'
    ],
    techFeatures: {
      user: 1,
      location: 1
    },
    buttonText: 'Jetzt kostenlos testen',
    adminButtonText: 'Auswählen',
    popular: false
  },
  {
    id: 'pro',
    title: 'Pro',
    description: 'Für wachsende Geschäfte',
    price: '29',
    features: [
      'Alles aus Basis',
      '10 Mitarbeiter',
      '10 Standorte',
      'Marketing-Tools',
      'Prioritäts-Support',
      'Benachrichtigungen per E-Mail & SMS',
      'KI-Assistent'
    ],
    techFeatures: {
      user: 10,
      location: 10
    },
    buttonText: 'Jetzt kostenlos testen',
    adminButtonText: 'Auswählen',
    popular: true
  },
  {
    id: 'enterprise',
    title: 'Enterprise',
    description: 'Für große Unternehmen',
    price: '999',
    features: [
      'Alles aus Pro',
      'Unbegrenzte Mitarbeiter',
      'Individuelle Integrationen',
      'Prioritäts-Support',
      'Anpassbare Berichte',
      'API-Integrationen',
      'Datenexport'
    ],
    techFeatures: {
      user: 100000,
      location: 100000
    },
    buttonText: 'Jetzt kostenlos testen',
    adminButtonText: 'Auswählen',
    popular: false
  }
];

export const Language = {
  DE: "DE",
  GB: "GB",
  FR: "FR",
  IT: "IT",
  ES: "ES",
  RO: "RO",
  RU: "RU",
  TR: "TR",
  PT: "PT",
  NL: "NL",
  NO: "NO",
  SV: "SV",
  FI: "FI",
  HU: "HU",
  PL: "PL",
  UA: "UA",
  BE: "BE",
  SR: "SR",
  HR: "HR",
  SL: "SL",
  MK: "MK",
  BG: "BG",
  LT: "LT",
  LV: "LV",
  EE: "EE",
  IS: "IS",
  AL: "AL",
  BA: "BA",
  SI: "SI",
  RS: "RS",
} as const;

export type Language = typeof Language[keyof typeof Language];

export const LanguageName = {
  DE: "Deutsch",
  GB: "Englisch",
  FR: "Französisch",
  IT: "Italienisch",
  ES: "Spanisch",
  RO: "Rumänisch",
  RU: "Russisch",
  TR: "Türkisch",
  PT: "Portugiesisch",
  NL: "Niederländisch",
  NO: "Norwegisch",
  SV: "Schwedisch",
  FI: "Finnisch",
  HU: "Ungarisch",
  PL: "Polnisch",
  UA: "Ukrainisch",
  BE: "Belarussisch",
  SR: "Serbisch",
  HR: "Kroatisch",
  SL: "Slowenisch",
  MK: "Mazedonisch",
  BG: "Bulgarisch",
  LT: "Litauisch",
  LV: "Lettisch",
  EE: "Estnisch",
  IS: "Isländisch",
  AL: "Albanisch",
  BA: "Bosnisch",
  SI: "Slowenisch",
  RS: "Serbisch",
} as const;

export const Tour = {
  EMPLOYEE: "employee",
  CUSTOMER: "customer",
  LOCATION: "location",
  DASHBOARD: "dashboard",
  COMPANY: "company",
} as const;

export type Tour = typeof Tour[keyof typeof Tour];

export const listOfCities = [
  'Leipzig',
  'Berlin',
  'Hamburg',
  'München',
  'Köln',
  'Frankfurt',
  'Stuttgart',
  'Düsseldorf',
  'Dortmund',
  'Dresden'
];
